import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import RichText from '../RichText/RichText'
import "./ArticleTeaser.scss"

class ArticleTeaser extends React.Component {
    render() {
      const { title, slug, content, createdAt, categories } = get(this, 'props.article')
      const createdAtDate = new Date(createdAt)
      if(content.raw) content.json = JSON.parse(content.raw)
      return (
        <Link to={`/article/${slug}`}>
          <article className="article-teaser">
            {/* <pre>{JSON.stringify(this.props.article)}</pre> */}
            {/* <pre>{JSON.stringify(categories)}</pre> */}
            <header>
              <h2 className="title">{title}</h2>
              <ul className="tags">
                <li>
                  <i className="fas fa-clock"></i>
                  <span>
                    {new Intl.DateTimeFormat("de-DE", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit"
                    }).format(createdAtDate)}
                  </span>
                </li>
                <li>
                  <i className="fas fa-star"></i>
                  <a href="/">Profile</a>
                </li>
                <li>
                  <i className="fas fa-tags"></i>
                  {(categories || []).map((item) => (
                    <a href="/" key={item.id}>
                      {item.name}
                    </a>
                  ))}
                </li>
              </ul>
            </header>
            <section className="content">
              <RichText content={content.json}></RichText>
            </section>
          </article>
        </Link>
      )
    }
  }

export default ArticleTeaser;
