import React from 'react'
import "./Header.scss"

class Header extends React.Component {
  render() {
    return (
      <header>
        <div className="navbar navbar-inverse navbar-fixed-top" role="navigation">
          <div className="container">
            <a className="navbar-brand" href="/"></a>
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
              <span className="sr-only">Nav</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse">
              <ul className="nav navbar-nav">
                <li><a href="/">Startseite</a></li>
                <li><a href="/profile/marken/">Marken</a></li>
                <li><a href="/profile/fahrer/">Fahrer</a></li>
                <li><a href="/profile/events/">Events</a></li>
                <li className="visible-xs" role="separator" className="divider"></li>
                <li className="visible-xs"><a href="/profile/fahrer/mawa/">Über Uns</a></li>
                <li className="visible-xs"><a href="/impressum/">Impressum</a></li>
                <li className="visible-xs"><a href="/datenschutz/">Datenschutz</a></li>
              </ul>
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="https://www.facebook.com/MoppedAnWeltAus/"><i className="fa fa-facebook" aria-hidden="true" data-target="_blank"></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/moppedanweltaus/"><i className="fa fa-instagram" aria-hidden="true" data-target="_blank"></i></a>
                </li>
                <li>
                  <a href="https://www.youtube.com/user/MoppedAnWeltAus"><i className="fa fa-youtube-play" aria-hidden="true" data-target="_blank"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
