import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/Layout/Layout'
import ArticleTeaser from '../components/ArticleTeaser/ArticleTeaser'
import Header from '../components/Header/Header'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulPost.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle} />
        <div className="wrapper">
          <Header />
          <section className="article-list">
            <div className="container">
              {posts.map(({ node }) => {
                return (
                  <ArticleTeaser article={node} key={node.id} />
                )
              })}
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default RootIndex


export const pageQuery = graphql`
  query HomeQuery {
    allContentfulPost(sort: {fields: [createdAt], order: DESC}, limit: 10) {
      edges {
        node {
          id
          createdAt
          title
          slug
          content {
            raw
            __typename
          }
          categories {
            id
            name
          }
        }
      }
    }
  }
`
  